"use client";

import { ErrorBoundary as HighlightErrorBoundary } from "@highlight-run/next/client";

export function ErrorBoundary({ children }: { children: React.ReactNode }) {
  if (process.env.NODE_ENV !== "production") {
    return children;
  }

  return <HighlightErrorBoundary showDialog>{children}</HighlightErrorBoundary>;
}
